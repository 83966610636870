import gql from "graphql-tag"

const START_ELIGIBILITY_IMPORT = gql`
  mutation startEligibilityImport(
    $uuid: String!
    $tenant: String!
    $started_by: String
    $allow_duplicate_orders: Boolean
  ) {
    startEligibilityImport(
      uuid: $uuid
      tenant: $tenant
      started_by: $started_by
      allow_duplicate_orders: $allow_duplicate_orders
    ) {
      status
    }
  }
`

export default START_ELIGIBILITY_IMPORT
