import gql from "graphql-tag"

const FETCH_ALL_ELIGIBILITY_IMPORTS_PAGINATED = gql`
  mutation fetchEligibilityImportsPaginated(
    $tenant: String!
    $search_text: String
    $limit: Int
    $offset: Int
    $import_type: String
  ) {
    fetchEligibilityImportsPaginated(
      tenant: $tenant
      search_text: $search_text
      limit: $limit
      offset: $offset
      import_type: $import_type
    ) {
      data {
        status
        uuid
        id
        file_key
        error_count
        error_file_key
        warning_file_key
        started_at
        created_at
        total_row_count
        successful_count
        import_type
        pre_ingestion_summary {
          cobrands {
            name
            count
          }
          total_kits
          total_invalid_rows
          duplicate_ordered_kit_error_count
          phone_present
          total_members
          total_records
          emails_present
          kits_per_client {
            name
            count
            count_by_program {
              name
              count
            }
            count_by_test_type {
              name
              count
            }
          }
          kits_per_program {
            name
            count
          }
          kits_per_kit_type {
            name
            count
          }
          members_per_client {
            name
            count
          }
          members_per_program {
            name
            count
          }
          preferred_languages {
            name
            count
          }
          physician_fax_present
          physician_npi_present
          physician_zip_present
          physician_city_present
          physician_phone_present
          physician_state_present
          missing_primary_physician
          physician_address_1_present
          physician_address_2_present
          physician_first_name_present
          physician_last_name_present
          physician_practice_name_present
          authorizing_address_1_present
          authorizing_address_2_present
          authorizing_city_present
          authorizing_fax_present
          authorizing_npi_present
          authorizing_phone_present
          authorizing_state_present
          authorizing_zip_present
          authorizing_first_name_present
          authorizing_last_name_present
          invalid_authorizing_providers
          insurance_carrier_code_present
          insurance_company_address1_present
          insurance_company_address2_present
          insurance_company_city_present
          insurance_company_name_present
          insurance_company_state_present
          insurance_company_zip_code_present
          insurance_group_number_present
          insurance_guarantor_address1_present
          insurance_guarantor_address2_present
          insurance_guarantor_city_present
          insurance_guarantor_name_present
          insurance_guarantor_phone_present
          insurance_guarantor_relationship_present
          insurance_guarantor_state_present
          insurance_guarantor_zip_code_present
          insurance_policy_number_present
          insurance_policyholder_address1_present
          insurance_policyholder_address2_present
          insurance_policyholder_city_present
          insurance_policyholder_dob_present
          insurance_policyholder_first_name_present
          insurance_policyholder_last_name_present
          insurance_policyholder_phone_present
          insurance_policyholder_sex_present
          insurance_policyholder_state_present
          insurance_policyholder_zip_code_present
          insurance_relationship_present
          incomplete_insurance_data
          incomplete_insurance_data_context {
            id
            name
            type
          }
          invalid_authorizing_providers_context {
            id
            name
            type
          }
          do_not_call_indicator_present
          do_not_email_indicator_present
          do_not_mail_indicator_present
          do_not_text_indicator_present
          total_primary_physicians
          exponential_ids_present
        }
        details {
          kits {
            total
            registered {
              count
            }
            count_by_test_type {
              name
              count
            }
            count_by_program_description {
              name
              count
            }
            count_by_enterprise_client_id {
              id
              count
              count_by_test_type {
                name
                count
              },
              count_by_program_description {
                name
                count
              },
              registered {
                count
              }
            }
          }
          members {
            total
          }
          primary_physicians {
            total
            invalid_pcp_addresses
          }
          physicians {
            total
            invalid_pcp_addresses
          }
          dncs {
            total
          }
        }
      }
      total_count
    }
  }
`

export default FETCH_ALL_ELIGIBILITY_IMPORTS_PAGINATED
